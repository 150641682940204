<template>
    <div id="listings_content" class="z-30 text-sectionTitle md:text-hero text-black w-screen lg:w-11/12 leading-tight">
      
        <!-- begin::title section -->
        <div class="flex items-center flex-col px-5 md:px-0 font-euclid">
            <p class="md:text-sectionTitle text-xl w-full text-left md:text-center font-understock uppercase">
                {{ $t('homepage.listings.title') }}</p>
            <p class="text-baselg w-full text-left md:text-center font-blackMango tracking-wider md:w-2/3 mt-0">
                {{ $t('homepage.listings.subtitle') }}</p>
                
                <ButtonHomepage @click="goToEgo(MyDistrict.name.pt)" bgColor="black" class="md:flex hidden mt-4" :text="$t('buttons.seeAllProperties')" />
            
        </div>
        <!-- end::title section -->

        <!-- begin::propeties -->
        <div v-if="false" class="hidden lg:flex flex-col justify-center mt-6 md:w-full px-5 md:px-10 lg:px-0 lg:w-full inset-x-0 mx-auto">
            <div class="relative font-euclid grid md:grid-cols-2 lg:grid-cols-3 py-10 px-14 lg:p-14 text-md bg-white mb-3 mt-4 rounded-2xl gap-4 lg:gap-6 lg:gap-y-8 lg:gap-y-10 w-full">
                <a
                    v-for="item in itemsList" 
                    :key="item.id"
                    class="cursor-pointer"
                    @click="generateLink(item.id)" 
                >
                    <PropertyCardBig 
                        :baths="new String(item.kpis.baths)" 
                        :reference="item.reference"
                        :cityAddress="getCityAddress(item)"
                        :rooms="new String(item.kpis.beds)" 
                        :location="$processLanguage(item.information.location.district)"
                        :garage="new String(item.parking)"
                        :area="new String(item.kpis.m2)"
                        :img="$mediaFile('properties', item.id , item.media.cover)"
                        :tag="item.tag" 
                        :slogan="$cutText(25, 120, new String($processLanguage(item.information.title))).replace('(...)', '...')"
                        :price="$currency(new String(item.price))" 
                        color="darkgray" 
                        :dev_id="item.id"
                        />
                </a>
                <!-- begin::navication arrows-->
                <button class="absolute left-5 inset-y-0 my-auto z-40" @click="decrementListingIndexShow" v-if="Math.ceil(getArrayLength / 3) > 1">
                    <inline-svg class="transform rotate-180" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>

                <button class="absolute right-5 inset-y-0 my-auto z-40" @click="incrementListingIndexShow" v-if="Math.ceil(getArrayLength / 3) > 1">
                    <inline-svg class="transform origin-center rotate-0" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>

                <!-- end::navigation arrows -->
                <div v-show="viewMoreOverlay" class="absolute w-full h-full view-more-overlay z-30 flex items-center justify-center">
                    <router-link :to="{ name: 'listings', params: { lang: this.$i18n.locale, data: '0' }}">
                        <ButtonHomepage bgColor="bronze" class="md:flex hidden mt-6" :text="$t('buttons.viewAll')" />
                    </router-link>
                </div>
            </div>
            <!-- end::propeties -->

            <!-- begin::navigation -->
            <NavigationIndex :currentNumber="currentIndex" :totalNumber="Math.ceil(getArrayLength / 3)" color="bronze" />
            <!-- end::navigation -->
        </div>
        <div v-if="false" class="mt-10 justify-center">
            <p class="font-euclid text-base">No properties available</p>
        </div>
          <!-- begin::propeties mobile -->
        <div class="lg:hidden mt-5 md:mt-8 pl-5 md:pl-10 w-screen block relative" v-if="false">
            <swiper :options="swiperOption" class="swiper" ref="swiperDevelopments">
                <swiper-slide v-for="item in mobileItemsList" :key="item.id">
                    <PropertyCardBig
                        class="cursor-ponter" 
                        :baths="new String(item.kpis.baths)" 
                        :rooms="new String(item.kpis.beds)"                         
                        :reference="item.reference"
                        :location="$processLanguage(item.information.location.district)"
                        :cityAddress="getCityAddress(item)"
                        :garage="new String(item.parking)"
                        :area="new String(item.kpis.m2)"
                        :img="$mediaFile('properties', item.id , item.media.cover)"
                        :tag="item.tag" 
                        :slogan="new String($processLanguage(item.information.title))"
                        :price="$currency(new String(item.price))" 
                        color="darkgray"
                        :dev_id="item.id" />
                </swiper-slide>
            </swiper>
        </div>
        <ButtonHomepage @click="goToEgo(MyDistrict.name.pt)" bgColor="black" class="md:hidden block mt-5" :text="$t('buttons.seeAll')" />
        <!-- end::propeties mobile -->
    
    </div>
</template>
<style lang="scss" scoped>
    .swiper-slide {
      width: 70%;
      .swiper-slide:last-child {
          width: 100%;
      }
      /* tablet */
      @media (min-width: 768px) {
          width: 30%;
          .swiper-slide:last-child {
              width: 100%;
          }
      }
  }
  .view-more-overlay{
        background: rgba(255,255,255,0.7);
    }
</style>
<script>
import PropertyCardBig from "@/components/listings/cards/PropertyCardBig";
    import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";
    import NavigationIndex from "@/components/navigationIndex/NavigationIndex.vue";

    import {
        Swiper,
        SwiperSlide
    } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    import { Property } from '@/store/structs/properties'
import mixinProperty from '@/models/properties/PropertiesModel'
     export default {

        name: "DistrictPageListings",
        mixins: [mixinProperty],
         components: {
            PropertyCardBig,
            ButtonHomepage,
            Swiper,
            SwiperSlide,
            NavigationIndex
        },
        props:['MyDistrict', 'typeListing'],
        data: function () {
            return {
                currentIndex:1,
                listings: null,
                listingsList: 6,
                listingsListMobile: 8,
                swiperOption: {
                    loop: false,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                },
                variableToIncrement: 6,
                realoadingDiv: 0,
                activeItems: null,
                viewMoreOverlay: false
            }
        },
        async created(){
            await this.getProperties()
            this.activeItems = this.listingsItems()
        },
        methods:{
            goToEgo(city){
                let isEnglish = this.$i18n.locale == 'en'
                if(this.$route.params.slug == 'vineyards')
                    city = 'vineyards'
                if(this.$route.params.slug == 'beachfront')
                    city = 'beachfront'
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties?loc=' + city.toUpperCase()
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis?loc=' + city.toUpperCase()
                }
            },
            async navigateToListings(){
                let myLinkData = "city:" + this.$processLanguage(this.MyDistrict.name)

                if(this.$route.params.slug == "vineyards"){
                    let myLinkData = "lifestyle:3"
                    this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
                    return
                }
                if(this.$route.params.slug == "beachfront"){
                    let myLinkData = "lifestyle:1"
                    this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
                    return
                }

                this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
            },
            getCityAddress(i){
                if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
                    return ''
                
                if(i.political != null)
                    return i.political + ', ' + i.administrative_area_level_3

                return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
            },
            listingsItems() {
                const retrievedData = this.$store.state.propertiesModule.properties;
                let condo_used = []
                const returningArray = [];
                if(this.typeListing == 'Districts'){
                    retrievedData.forEach(element => {
                        if(this.$processLanguage(element.district.name) == this.$processLanguage(this.MyDistrict.name))
                            if(!condo_used.includes(element.condominium_id)){
                                returningArray.push(new Property(element))
                                if(element.condominium_id != null)
                                    condo_used.push(element.condominium_id)
                            }
                    });
                }else if(this.typeListing == 'vineyards'){
                    // FEATURE ID = 28
                    retrievedData.forEach(element => {
                        if(element.features.length != undefined){
                            let featuresArray = element.features.split(',')
                            if(featuresArray.includes('28'))
                                if(!condo_used.includes(element.condominium_id)){
                                returningArray.push(new Property(element))
                                if(element.condominium_id != null)
                                    condo_used.push(element.condominium_id)
                            }
                        }
                    });
                }else{
                    // FEATURE ID = 6
                    retrievedData.forEach(element => {
                        if(element.features.length != undefined){
                            let featuresArray = element.features.split(',')
                            if(featuresArray.includes('6'))
                                if(!condo_used.includes(element.condominium_id)){
                                returningArray.push(new Property(element))
                                if(element.condominium_id != null)
                                    condo_used.push(element.condominium_id)
                            }
                        }
                    });
                }
                return returningArray
            },
            incrementListingIndexShow(){
              if((this.currentIndex) >= (this.getArrayLength / 3)) return this.viewMoreOverlay = true;
              this.currentIndex++;
              if(this.currentIndex == Math.ceil(this.getArrayLength/3)) {
                    var that = this;
                    setTimeout(function(){
                        that.viewMoreOverlay = true;
                    },2000)
                }
            },
            decrementListingIndexShow(){
              if((this.currentIndex - 1)  == 0) return;
              this.currentIndex--;
              this.viewMoreOverlay = false;
            },
            generateLink(id){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
            },
        },
        computed:{
            getMyDistrict(){
                return this.MyDistrict
            },            
            itemsList(){
                const s0 = (this.currentIndex - 1) * 3;
                const s1 = this.currentIndex * 3;
                return this.listingsItems().slice(s0, s1);
            },
            mobileItemsList(){
                return this.listingsItems()
            },
            getArrayLength(){
                let myArray = this.listingsItems()
                return myArray.length
            },
        }
     }
</script>
